<template>
  <div class="m_videoPlayer" :style="`height: ${videoPlayerHeight}px`">
    <div class="player">
      <video-player
        ref="videoPlayer"
        v-if="playerListInfo.length > 0"
        :if-drag-seek="playerInfo.isWanCheng"
        :focus="false"
        :ifBrush="true"
        :brushTime="1800"
        @play="playerStart"
        @timeupdate="videoPlayerTimeUpDate"
        @ended="playEnded"
        :playerList="playerListInfo"
        :currentUrl="playerIndex"
      />
    </div>
    <div class="courseInfo" ref="courseInfo">
      <v-clamp autoresize :max-lines="1" class="title">
        {{ playerInfo.courseContName }}
        <template
          v-slot:after="{ expand, collapse, toggle, clamped, expanded }"
        >
          <span
            @click="resizeHeight(expand)"
            v-if="clamped"
            class="expand-collapse"
            ><svg-icon class="expandicon" icon="icon-rongqi" />展开</span
          >
          <span
            @click="resizeHeight(collapse)"
            v-if="expanded"
            class="expand-collapse"
            ><svg-icon class="expandedicon" icon="icon-rongqi" />收起</span
          >
        </template>
      </v-clamp>
      <v-clamp autoresize :max-lines="3" class="synopsis">
        简介：{{ playerInfo.introduction }}
        <template
          v-slot:after="{ expand, collapse, toggle, clamped, expanded }"
        >
          <span
            @click="resizeHeight(expand)"
            v-if="clamped"
            class="expand-collapse"
            ><svg-icon class="expandicon" icon="icon-rongqi" />展开</span
          >
          <span
            @click="resizeHeight(collapse)"
            v-if="expanded"
            class="expand-collapse"
            ><svg-icon class="expandedicon" icon="icon-rongqi" />收起</span
          >
        </template>
      </v-clamp>
    </div>
    <!--    <div class="studyProgress">-->
    <!--      <div class="top">-->
    <!--        <div class="left">当前学习进度</div>-->
    <!--        <div class="right"><span>20%</span></div>-->
    <!--      </div>-->
    <!--      <div class="bottom">-->
    <!--        <van-progress-->
    <!--          color="#BA2926"-->
    <!--          :show-pivot="false"-->
    <!--          track-color="#D8D8D8"-->
    <!--          :stroke-width="6"-->
    <!--          :percentage="percentage"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="courseCatalogue">
      <div class="title">
        课程目录<span class="value"
          >（ {{ playUrlIndex }} / {{ playerList.length }} ）</span
        >
      </div>
      <div class="body" ref="scroll">
        <van-list finished-text="没有更多了">
          <div
            class="item"
            :id="`currentStudy${index + 1}`"
            :class="item.currentStudy ? 'itemPlayer' : ''"
            v-for="(item, index) in playerList"
            :key="index"
            @click="playClick(item)"
          >
            <div class="top">
              <img
                v-if="item.currentStudy"
                src="@/assets/icon/loading.gif"
                style="max-width: 20px; width: 20px; margin-right: 5px"
                alt=""
                srcset=""
              />
              <div class="titles">
                {{ item.courseContName }}
              </div>
            </div>
            <div class="right" v-if="item.isWanCheng && item.isTongGuo">
              <svg-icon
                icon="icon-wancheng"
                style="font-size: 20px"
                color="#67C23A"
              />
            </div>
            <div
              v-if="item.isWanCheng && !item.isTongGuo"
              class="list-item-status"
            >
              待考试
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from "@/components/videoPlayer.vue";
import VClamp from "vue-clamp";
import SvgIcon from "@/components/svgIcon.vue";
import { getGongXuProcessUserCourseList } from "@/api/publicDemandController";
import {
  getStudyProcessEnd,
  getStudyProcessStart,
  getStudyProcessUpdate,
} from "@/api/specializedController";
import { showModel } from "@/utils/tools";
import * as storage from "@/utils/storage";

export default {
  name: "m_videoPlayer",
  components: { SvgIcon, VideoPlayer, VClamp },
  activated() {
    console.log("onshow播放");
  },
  computed: {
    satisfactionLabel() {
      return (t) => {
        let result = "";
        if (t === 1) {
          result = "很不满意";
        }
        if (t === 2) {
          result = "不满意";
        }
        if (t === 3) {
          result = "一般";
        }
        if (t === 4) {
          result = "满意";
        }
        if (t === 5) {
          result = "非常满意";
        }

        return result;
      };
    },
    playUrlIndex() {
      return this.playerList.findIndex((item) => item.currentStudy == true) + 1;
    },
    yearId() {
      return storage.getStore("yearId");
    },
  },
  data() {
    return {
      currentRate: 0,
      percentage: 20,
      appraise: "",
      satisfactionValue: 5,
      playerInfo: [],
      playerList: [],
      playerListInfo: [],
      playerIndex: 0,
      studyProcessId: 0,
      forTheFirstTime: true,
      editPlayTimer: null,
      currentTime: 0,
      videoPlayerHeight: 0,
    };
  },
  created() {
    this.courseContId = this.$route.query.courseContId;
    this.getPublicInfo();
  },
  beforeDestroy() {
    clearInterval(this.editPlayTimer);
    this.editPlayTimer = null;
  },
  methods: {
    resizeHeight(callBack) {
      const that = this;
      if (typeof callBack === "function") {
        callBack();
      }
      setTimeout(() => {
        that.videoPlayerHeight = 560 + that.$refs.courseInfo.offsetHeight;
      }, 200);
    },
    // 获取公需课详情
    getPublicInfo() {
      this.playerListInfo = [];
      getGongXuProcessUserCourseList({
        courseContId: this.courseContId,
        trainingPlanId: this.yearId,
      }).then((res) => {
        this.playerList = JSON.parse(JSON.stringify(res.data.courseList));
        this.playerInfo = res.data;
        res.data.videoUrl = JSON.parse(res.data.videoUrl);
        res.data.videoUrl.map((item) => {
          // item.url = "http://zyys.ihehang.com" + item.url;
          item.url = item.url;
        });
        this.playerListInfo = JSON.parse(JSON.stringify(res.data.videoUrl));
        if (res.data.isWanCheng && !res.data.isTongGuo) {
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + res.data.studyCourseId
              );
            },
          });
        }
        this.$nextTick(() => {
          document
            .getElementById(`currentStudy${this.playUrlIndex}`)
            .scrollIntoView({ block: "start" });
          this.resizeHeight();
        });
      });
    },
    // 开始播放视屏
    playerStart(args) {
      const that = this;
      if (that.forTheFirstTime) {
        this.forTheFirstTime = false;
        getStudyProcessStart({
          ppXmWjbzp5b8: this.playerInfo.courseContId,
          c3R1ZHlDb3Vyc2VJZA: this.playerInfo.studyCourseId,
          trainingId: this.yearId,
        }).then((res) => {
          this.studyProcessId = res.data.studyProcessId;
          // 自动转跳到记录播放时间
          if (res.data.playingTime) {
            args.currentTime(res.data.playingTime);
          }
          // 每间隔2分钟记录一次时间
          if (!this.editPlayTimer) {
            this.editPlayTimer = setInterval(() => {
              that.setStudyProcessEdit();
            }, 120000);
          }
        });
      }
    },
    // 记录播放时间请求
    setStudyProcessEdit() {
      getStudyProcessUpdate({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        cgxhewluz1rpbwu: Math.ceil(this.currentTime),
        trainingId: this.yearId,
      }).then(() => {});
    },
    // 进度条变化
    videoPlayerTimeUpDate(args) {
      this.currentTime = args.currentTime();
      this.$set(
        this.playerList[this.playerIndex],
        "progress",
        Math.floor((args.currentTime() / args.duration()) * 100)
      );
    },
    // 视频播放结束请求
    playEnded() {
      getStudyProcessEnd({
        c3R1ZHlQcm9jZXNzSWQ: this.studyProcessId,
        trainingId: this.yearId,
      }).then((res) => {
        if (this.editPlayTimer) {
          clearInterval(this.editPlayTimer);
          this.editPlayTimer = null;
        }
        this.playerInfo.isWanCheng = true;
        this.forTheFirstTime = true;
        this.$set(this.playerList[this.playUrlIndex - 1], "isWanCheng", true);
        if (res.data.state === 2) {
          showModel({
            content: "恭喜您已经完成所有课程学习，请前往考试吧",
            showClose: true,
            showCancelButton: true,
            confirmButtonText: "前往考试",
            closeModal: false,
            confirm: () => {
              console.log("前往考试");
              this.keepAliveNavigation(
                "examination?courseId=" + res.data.courseId
              );
            },
          });
        }
      });
    },
    playClick(item) {
      if (this.courseContId == item.courseContId) {
        return false;
      }
      this.courseContId = item.courseContId;
      if (this.editPlayTimer) {
        clearInterval(this.editPlayTimer);
        this.editPlayTimer = null;
      }
      this.forTheFirstTime = true;
      const query = JSON.parse(JSON.stringify(this.$route.query));
      query.courseContId = item.courseContId;
      this.$router.replace({
        path: this.$route.path,
        query: query,
      });
      this.getPublicInfo();
    },
  },
};
</script>
<style scoped lang="scss">
.m_videoPlayer {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 61px);
  .courseCatalogue {
    overflow: hidden;
    flex: 1;
    margin-bottom: 0;
    .body {
      height: calc(100% - 24px);
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
.expandicon {
  transform: rotate(180deg);
}

.m_myCourse {
  height: 100%;
  width: 100%;
}

/deep/ .m-body {
  padding: 0px !important;
}

.player {
  width: 100%;
  height: 210px;
}

.courseInfo {
  background: #ffffff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  .title {
    font-size: 16px;
    font-weight: 500;

    .expand-collapse {
      margin-left: 10px;
      font-size: 12px !important;
      color: #818496;
    }
  }
  .synopsis {
    color: #818496;
    font-size: 12px;
    margin-top: 10px;
  }
}

.studyProgress {
  background: #ffffff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  margin: 12px auto;
  .top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    .left {
      font-size: 16px;
      color: #505363;
    }

    .right {
      font-size: 16px;
      font-weight: 500;
      span {
        color: #ba2926;
        font-size: 16px;
      }
    }
  }

  .bottom {
    margin-top: 5px;
  }
}

.courseCatalogue {
  background: #ffffff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  margin: 12px auto;
  .title {
    color: #21252e;
    font-size: 16px;
    font-weight: 500;

    .value {
      font-size: 12px;
      color: #818496;
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    background: rgba(204, 204, 204, 0.12);
    border-radius: 4px;
    margin: 15px auto;
    .top {
      display: flex;
      .titles {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-weight: 500;
        margin-top: 5px;
        color: #505363;
      }
    }
    &.itemPlayer {
      background: rgba(22, 119, 254, 0.06);
      .titles {
        color: #1677fe;
      }
    }
    .right {
      width: 60px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: flex-end;
      min-width: 60px;
    }
    .list-item-status {
      font-size: 12px;
      width: 45px;
      white-space: nowrap;
      padding: 2px 5px;
      border-radius: 4px;
      color: #fff;
      display: inline-block;
      background: #e6a23c;
      box-sizing: border-box;
    }
  }
}
</style>
